/*
 * @Name         : 
 * @Version      : 1.0.0
 * @Author       : Jaron Jia
 * @Company      : JaronSoft LLC & DCSDATA LLC
 * @Date         : 2023-11-28 16:19:22
 * @LastEditors  : Jaron Jia
 * @LastEditTime : 2023-12-08 10:23:26
 * @FilePath     : /SGIS.1-1-0.com.cn/v2/src/pages/index/layout.tsx
 * Copyright(C) 2023,JaronSoft LLC.
 *  以下公司保留代码的一切权利
 * (C)2007-2023 扬州市佳融信息技术有限公司
 * (C)2015-2023 扬州运帷数据技术有限公司
 * 禁止将代码用于非上述公司的项目
 */
import { useState } from "react";
import { Outlet, useLocation } from "ice";
import { Shell, ConfigProvider } from "@alifd/next";
import store from "@/store";
import Footer from "@/components/Footer";
import PageNav from "@/components/PageNav";
import HeaderAvatar from "@/components/HeaderAvatar";
import Notice from "@/components/Notice";
import GlobalSearch from "@/components/GlobalSearch";
import Logo from "@/components/Logo";

interface IGetDevice {
  (width: number): "phone" | "tablet" | "desktop";
}
const getDevice: IGetDevice = (width) => {
  const isPhone =
    typeof navigator !== "undefined" &&
    navigator &&
    navigator.userAgent.match(/phone/gi);

  if (width < 680 || isPhone) {
    return "phone";
  } else if (width < 1280 && width > 680) {
    return "tablet";
  } else {
    return "desktop";
  }
};

export default function Layout() {
  // console.log('应用名称',process.env.ICE_APP_NAME)
  // console.log('ICE_CORE_MODE',process.env.ICE_CORE_MODE)
  // console.log('ICE_CORE_ROUTER',process.env.ICE_CORE_ROUTER)
  // console.log('ICE_CORE_ERROR_BOUNDARY',process.env.ICE_CORE_ERROR_BOUNDARY)
  // console.log('ICE_CORE_INITIAL_DATA',process.env.ICE_CORE_INITIAL_DATA)
  // console.log('ICE_CORE_DEV_PORT',process.env.ICE_CORE_DEV_PORT)
  // console.log('process.env',process.env)
  
  const location = useLocation();
  const [device, setDevice] = useState(getDevice(NaN));

  if (typeof window !== "undefined") {
    window.addEventListener("optimizedResize", (e) => {
      const deviceWidth =
        (e && e.target && (e.target as Window).innerWidth) || NaN;
      setDevice(getDevice(deviceWidth));
    });
  }

  const [userState] = store.useModel("user");

  if (["Login"].includes(location.pathname)) {
    return <Outlet />;
  }

  return (
    <ConfigProvider device={device}>
      <Shell
        style={{
          minHeight: "100vh",
        }}
        type="brand"
        fixedHeader={false}
      >
        <Shell.Branding>
          <Logo image="/favicon.png" text={process.env.ICE_APP_NAME} />
        </Shell.Branding>
        <Shell.Navigation
          direction="hoz"
          style={{
            marginRight: 10,
          }}
        >
          {/* <GlobalSearch /> */}
        </Shell.Navigation>
        <Shell.Action>
          <Notice />
          <HeaderAvatar
            name={userState.currentUser.name}
            avatar={userState.currentUser.avatar}
          />
        </Shell.Action>
        <Shell.Navigation>
          <PageNav />
        </Shell.Navigation>
        <Shell.Content>
          <Outlet />
        </Shell.Content>
        <Shell.Footer>
          <Footer />
        </Shell.Footer>
      </Shell>
    </ConfigProvider>
  );
}
